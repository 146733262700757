import {
    actualizarEstado,
    enviarJson,
    statusSteps,
    actualizarScoreInicioFlujo,
    finalUpdateScore,
    generarZip,
    enviarCorreoAceptacion,
    enviarImagenEvaluar
} from '../services/api.js';
import {
    v4 as uuidv4
} from 'uuid';
import {
    obtenerValorConfig,
    obtenerValorUser
} from './configStyle';

let objetoFinal = {};

export const agregarInfo = (dataUser, elemento) => {
    let dataUserLS = JSON.parse(localStorage.getItem("data_user"))
    const elementoEncontrado = dataUserLS.findIndex(function (item) {
        return item.description === elemento.description
    })
    ////console.log("elementoEncontrado" + elementoEncontrado + " item:"+elemento);
    if (elementoEncontrado !== -1) {
        dataUserLS[elementoEncontrado] = elemento
    } else {
        dataUserLS.push(elemento)
    }
    localStorage.setItem("data_user", JSON.stringify(dataUserLS))
}


export const hayExcepcion = () => {
    const uuidExcepcion = ["Wco5CTXw7e0JoKR9p7TB9fqOjpUS7iuu", "VCVi90WBhqtRPWboXtfpHBQlLPO00jd4", "MQZq6jzleILkHbQhGULIdwPhn5Jv19HD"];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem("apikeyOtorgante")) {
            respuesta = true
        }
    });
    return respuesta
}

export const regresarJson = () => {
    const uuidExcepcion = ["iQNTQ2qoBLfAy0iH4V2OUM5l36BIUoCt"];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem("apikeyOtorgante")) {
            respuesta = true
        }
    });
    return respuesta
}

export const esEditable = () => {
    //lso campos son editables para CDC y otorgantes
    const uuidExcepcion = ["0zmV8ljIWoFfGsaieC4vuEPwpVKYYrAP", "MTMba54LICmL2Boez3KuFdVL1vD1boKk", "7a531a75-e0cf-4158-bd99-86b733ef22ad", "94b502fd-5546-44e1-b12f-2122d410cea0"];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem("apikeyOtorgante")) {
            respuesta = true
        }
    });
    //console.log("respuesta", respuesta);
    return respuesta
}

export const initStatus = async(nombreOtorgante) => {
    //console.log("entre a initStatus");
    // if (window.opener) {
    //     window.opener.postMessage(JSON.stringify({
    //         step: "inicio_page",
    //         result: "OK",
    //         //data: { transaccion: localStorage.getItem("uuidTrx")}
    //     }), new URL(window.location).searchParams.get("referer"))
    //     //console.log("hay referer");
    //     window.opener.postMessage(JSON.stringify({
    //         step: "inicio",
    //         result: "OK",
    //         data: {
    //             transaccion: localStorage.getItem("uuidTrx")
    //         }
    //     }), new URL(window.location).searchParams.get("referer"))
    //     localStorage.setItem("externalReference", new URL(window.location).searchParams.get("referer"))
    //     localStorage.setItem("externalJWT", new URL(window.location).searchParams.get("jwt"))
    // }
    if (new URL(window.location).searchParams.get("referer")) {
        localStorage.setItem("externalReference", new URL(window.location).searchParams.get("referer"))
    }
    if (new URL(window.location).searchParams.get("schema")) {
        localStorage.setItem("package", new URL(window.location).searchParams.get("schema"))
    }
    return await updateInitStatus(nombreOtorgante);
}

const updateInitStatus = async (nombreOtorgante) => {
    if (localStorage.getItem("completado") === "false") {
        let tipo = '';
        let informacion = {};
        let trx_id = localStorage.getItem("uuidTrx");
        if (trx_id === null) {
            //console.log("se creo nuevo trx");
            trx_id = uuidv4();
            localStorage.setItem("uuidTrx", trx_id);
        }
        let json = {
            "status": "OK",
            "step": "inicio"
        }
        let company_id = localStorage.getItem("apikeyOtorgante");
        let client_id = localStorage.getItem("uuidUser");
        let event = JSON.stringify(json);
        let objetoStatus = {
            'trx_id': trx_id,
            'company_id': company_id,
            'client_id': client_id,
            'step': "inicio_page",
            'event': event,
            'origin': "celd",
            'nombreOtorgante': nombreOtorgante
        }
        //console.log("uuidtx:", localStorage.getItem("uuidTrx"));
        return actualizarEstado(objetoStatus).then(({ status }) => {
            if (status === 200) {
                //console.log("Se actualizo status INIT");
                informacion.status = status;
                informacion.dataLifeCycle = objetoStatus;
                tipo = 'Update Cycle';
                evento('Inicio', tipo, informacion, true);
                initUpdateScore();
            }
        }).catch((error) => {
            //console.log("No se pudo actualizar init Status. "+e);
            //setLoading(false)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error Update Cycle';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception Update Cycle';
            }
            evento('Inicio', tipo, informacion, false);
        })
    } else {
        return
    }
}

export const status = (paso, updatepaso) => {
    let json = {
        "status": "OK",
        "step": updatepaso
    }
    let event_t = JSON.stringify(json);

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }
    updateStatus(objetoStatus);
    // if (window.opener) {
    //     window.opener.postMessage(JSON.stringify({
    //         step: paso,
    //         result: "OK"
    //     }), localStorage.getItem("externalReference"))
    // }
}

export const statusData = (paso, datos) => {
    var json = {};
    json = {
        "status": "OK",
        "step": paso,
        "description": datos
    }
    let event_t = JSON.stringify(json)

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }
    updateStatus(objetoStatus);
    // if (window.opener) {
    //     window.opener.postMessage(JSON.stringify({
    //         step: paso,
    //         result: "OK",
    //         data: datos
    //     }), localStorage.getItem("externalReference"))
    // }
}

export const statusError = (paso, datos, tipo) => {
    //console.log("entre a status cancelar");
    var json = {};
    let status = "INCOMPLETED"
    if (tipo !== "cancelado") {
        status = "FAIL"
    }
    json = {
        "status": status,
        "step": paso,
        "description": datos
    }
    let event_t = JSON.stringify(json);

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }
    updateStatus(objetoStatus);
    // if (window.opener) {
    //     window.opener.postMessage(JSON.stringify({
    //         step: paso,
    //         status: status,
    //         type: "PROCESS",
    //         error_text: datos,
    //     }), localStorage.getItem("externalReference"))
    //     if (tipo === "cancelado")
    //         window.close()
    // }
}


const updateStatus = async (objetoInformacion) => {
    if (localStorage.getItem("completado") === "false") {
        let tipo = '';
        let informacion = {};
        let dataEvent = null;
        let trx_id = localStorage.getItem("uuidTrx");
        let company_id = localStorage.getItem("apikeyOtorgante");
        let client_id = localStorage.getItem("uuidUser");
        const dataOtorgante = (JSON.parse(localStorage.getItem("dataOtorgante")));
        const dataUser = (JSON.parse(localStorage.getItem("data_user")));
        const bandera = localStorage.getItem("flag");

        let telefono = localStorage.getItem("movil") ? localStorage.getItem("movil") : '';
        let correo = localStorage.getItem("correoElectronico") ? localStorage.getItem("correoElectronico") : '';
        let flag = bandera ? bandera : '';
        let nombres = dataUser ? obtenerValorUser(dataUser, "nombre", "") : '';
        let apellidoPaterno = dataUser ? obtenerValorUser(dataUser, "apellidoPaterno", "") : '';
        let apellidoMaterno = dataUser ? obtenerValorUser(dataUser, "apellidoMaterno", "") : '';
        let fullName = `${nombres} ${apellidoPaterno} ${apellidoMaterno}`;
        let tipoIdentificacion = localStorage.getItem("typeID");

        let objetoStatus = {
            'trx_id': trx_id,
            'company_id': company_id,
            'client_id': client_id,
            'origin': "celd",
            'nombreOtorgante': obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', ""),
            'nombreCompleto': fullName,
            'telefono': telefono,
            'correoElectronico': correo,
            'bandera': flag,
            'typeID': tipoIdentificacion ? tipoIdentificacion : '',

        }

        objetoFinal = {
            ...objetoStatus,
            ...objetoInformacion
        }

        dataEvent = JSON.parse(objetoInformacion.event)

        actualizarEstado(objetoFinal).then(({ status }) => {
            if (status === 200) {
                //console.log("Se actualizo status INIT");
                informacion.status = dataEvent.status;
                informacion.dataLifeCycle = objetoFinal;
                tipo = 'Update Cycle';
                evento(dataEvent.step, tipo, informacion, true);
            }
        }).catch((error) => {
            //console.log("No se pudo actualizar init Status. "+e);
            //setLoading(false)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error Update Cycle';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception Update Cycle';
            }
            evento(dataEvent.step, tipo, informacion, false);
        })
    } else {
        return
    }
}


/* Validaciones */

export const hayLetras = (input) => {
    let result = true
    var expreg = /[a-z]/gi;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    return result
}

export const esNumLetras = (input) => {
    let result = true
    var expreg = /^[A-Za-z0-9]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esNumLetras",result);
    return result
}

export const esSoloLetras = (input) => {
    let result = true
    var expreg = /^[A-Za-z\s]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esSoloLetras",result);
    return result
}

export const esNumeros = (input) => {
    let result = true
    var expreg = /^[0-9]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esnum",result);
    return result
}

export const statusReintento = (paso, datos, tipo) => {
    var json = {};
    let status = tipo
    json = {
        "status": status,
        "step": paso,
        "description": datos
    }

    let event_t = JSON.stringify(json)

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }

    updateStatus(objetoStatus);
}

export const sendData = (objetoStatus) => {
    updateStatus(objetoStatus);
}

export const esValidoEmail = (valor) => {
    if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(valor)) {
        return true
    } else {
        return false
    }
}

export const enviarObjetoATD = (finished, error) => {
    let tipo = '';
    let informacion = {};
    let dataUserLS = localStorage.getItem("data_user");
    let dataUser = dataUserLS ? JSON.parse(dataUserLS) : [];
    let folio = localStorage.getItem('uuidUser')
    let flag = localStorage.getItem('flag');

    let nombre = dataUser.length > 0 && obtenerValorUser(dataUser, "nombre", '');
    let apellidoPaterno = dataUser.length > 0 && obtenerValorUser(dataUser, "apellidoPaterno", '');
    let apellidoMaterno = dataUser.length > 0 && obtenerValorUser(dataUser, "apellidoMaterno", '');
    let nombreCompleto = `${nombre} ${apellidoPaterno} ${apellidoMaterno}`
    let curp = dataUser.length > 0 && obtenerValorUser(dataUser, "curp");
    let email = dataUser.length > 0 && obtenerValorUser(dataUser, "correoElectronico");
    let cp = dataUser.length > 0 && obtenerValorUser(dataUser, "codigoPostal");
    let pais = dataUser.length > 0 && obtenerValorUser(dataUser, "pais");
    let estado = dataUser.length > 0 && obtenerValorUser(dataUser, "estado");
    let municipio = dataUser.length > 0 && obtenerValorUser(dataUser, "delegacion");
    let colonia = dataUser.length > 0 && obtenerValorUser(dataUser, "colonia");
    let calle = dataUser.length > 0 && obtenerValorUser(dataUser, "calleCompleta");
    let nacimiento = dataUser.length > 0 && obtenerValorUser(dataUser, "nacimiento");

    let domicilio = {
        cp: cp ? cp : '',    // operador ternario para validar si la variable no es nulo
        pais: pais ? pais : '',
        estado: estado ? pais : '',
        municipio: municipio ? municipio : '',
        colonia: colonia ? colonia : '',
        calle: calle ? calle : '',
    }
    let jsonFinal = {
        folio: folio ? folio : '',
        finished: finished,
        error: error,
        flag: (flag && flag === 'green') ? true : false,
        nombreCompleto: nombreCompleto ? nombreCompleto : '',
        nombre: nombre ? nombre : '',
        apellidoPaterno: apellidoPaterno ? apellidoPaterno : '',
        apellidoMaterno: apellidoMaterno ? apellidoMaterno : '',
        curp: curp ? curp : '',
        email: email ? email : '',
        domicilio: domicilio ? domicilio : {},
        nacimiento: nacimiento ? nacimiento : '',
    }
    enviarJson(jsonFinal).then(({ status, data: { message } }) => {
        informacion.status = status;
        informacion.mensaje = message;
        evento('Enviar objeto ATD', 'Success', informacion, true);
    }).catch(error => {
        if (error.response) {
            let { data: { message }, status } = error.response
            informacion.mensaje = message;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento('Enviar objeto ATD', tipo, informacion, false);
    })
}

/* Evento */

export const evento = (paso, tipo, dato, finished) => {
    statusSteps(paso, tipo, dato, finished).then(response => { return }).catch(error => console.log('ERROR', error));
}

const initUpdateScore = () => {
    let tipo = '';
    let informacion = {};
    actualizarScoreInicioFlujo().then(({ status, data: { payload } }) => {
        if (status === 200) {
            informacion.status = status;
            informacion.payload = payload;
            tipo = 'Success';
            evento('Actualizar Score', tipo, informacion, true);
        }
    }).catch(error => {
        if (error.response) {
            let { data: { message }, status } = error.response
            informacion.mensaje = message;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento('Actualizar Score', tipo, informacion, false);
    })
}

// Paso de pivote en caso de terminar con los reintentos y envía un json limpio 
export const sendStatusBlocked = async (objetoInformacion, flag) => {

    let paso, datos, status = ''

    if (objetoInformacion.step === 'captura_id') {
        status = "INCOMPLETED"
        paso = "Captura ID";
        datos = "Bloqueo por reintentos de captura de identificación"
    } else {
        status = "INCOMPLETED"
        paso = "Captura Video Token";
        datos = "Bloqueo por reintentos de Video Token"
    }

    let json = {};
    json = {
        "status": status,
        "step": paso,
        "description": datos
    }
    let event_t = JSON.stringify(json);

    let objetoPaso = {
        'step': paso,
        'event': event_t
    }

    if (localStorage.getItem("completado") === "false") {

        let tipo = '';
        let informacion = {};
        let dataEvent = null;
        let trx_id = localStorage.getItem("uuidTrx");
        let company_id = localStorage.getItem("apikeyOtorgante");
        let client_id = localStorage.getItem("uuidUser");
        const dataOtorgante = (JSON.parse(localStorage.getItem("dataOtorgante")));
        const dataUser = (JSON.parse(localStorage.getItem("data_user")));

        let telefono = localStorage.getItem("movil") ? localStorage.getItem("movil") : '';
        let correo = localStorage.getItem("correoElectronico") ? localStorage.getItem("correoElectronico") : '';
        let nombres = dataUser ? obtenerValorUser(dataUser, "nombre", "") : '';
        let apellidoPaterno = dataUser ? obtenerValorUser(dataUser, "apellidoPaterno", "") : '';
        let apellidoMaterno = dataUser ? obtenerValorUser(dataUser, "apellidoMaterno", "") : '';
        let fullName = `${nombres} ${apellidoPaterno} ${apellidoMaterno}`;

        let objetoStatus = {
            'trx_id': trx_id,
            'company_id': company_id,
            'client_id': client_id,
            'origin': "celd-cl",
            'nombreOtorgante': obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', ""),
            'nombreCompleto': fullName,
            'telefono': telefono,
            'correoElectronico': correo,
            'bandera': flag
        }

        objetoFinal = {
            ...objetoStatus,
            ...objetoPaso
        }

        dataEvent = JSON.parse(objetoPaso.event)

        actualizarEstado(objetoFinal).then(({ status }) => {
            if (status === 200) {
                //console.log("Se actualizo status INIT");
                informacion.status = dataEvent.status;
                informacion.dataLifeCycle = objetoFinal;
                tipo = 'Update Cycle';
                evento(dataEvent.step, tipo, informacion, true);
                flowBlocked(objetoInformacion, flag);
            }
        }).catch((error) => {
            //console.log("No se pudo actualizar init Status. "+e);
            //setLoading(false)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error Update Cycle';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception Update Cycle';
            }
            evento(dataEvent.step, tipo, informacion, false);
            localStorage.setItem("completado", "true");
        });
    } else {
        return
    }
}

// Forza que la primer petición del json limpio responda 200 y pueda entrar esta funcion
const flowBlocked = (objetoInformacion, flag) => {
    if (localStorage.getItem("completado") === "false") {

        let tipo = '';
        let informacion = {};
        let dataEvent = null;
        let trx_id = localStorage.getItem("uuidTrx");
        let company_id = localStorage.getItem("apikeyOtorgante");
        let client_id = localStorage.getItem("uuidUser");
        const dataOtorgante = (JSON.parse(localStorage.getItem("dataOtorgante")));
        const dataUser = (JSON.parse(localStorage.getItem("data_user")));

        let telefono = localStorage.getItem("movil") ? localStorage.getItem("movil") : '';
        let correo = localStorage.getItem("correoElectronico") ? localStorage.getItem("correoElectronico") : '';
        let nombres = dataUser ? obtenerValorUser(dataUser, "nombre", "") : '';
        let apellidoPaterno = dataUser ? obtenerValorUser(dataUser, "apellidoPaterno", "") : '';
        let apellidoMaterno = dataUser ? obtenerValorUser(dataUser, "apellidoMaterno", "") : '';
        let fullName = `${nombres} ${apellidoPaterno} ${apellidoMaterno}`;

        let objetoStatus = {
            'trx_id': trx_id,
            'company_id': company_id,
            'client_id': client_id,
            'origin': "celd-cl",
            'nombreOtorgante': obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', ""),
            'nombreCompleto': fullName,
            'telefono': telefono,
            'correoElectronico': correo,
            'bandera': flag
        }

        objetoFinal = {
            ...objetoStatus,
            ...objetoInformacion
        }

        dataEvent = JSON.parse(objetoInformacion.event)

        actualizarEstado(objetoFinal).then(({ status }) => {
            if (status === 200) {
                //console.log("Se actualizo status INIT");
                informacion.status = dataEvent.status;
                informacion.dataLifeCycle = objetoFinal;
                tipo = 'Update Cycle';
                evento(dataEvent.step, tipo, informacion, true);
                localStorage.setItem("completado", "true");
            }
        }).catch((error) => {
            //console.log("No se pudo actualizar init Status. "+e);
            //setLoading(false)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error Update Cycle';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception Update Cycle';
            }
            evento(dataEvent.step, tipo, informacion, false);
            localStorage.setItem("completado", "true");
        });
    } else {
        return
    }
}

export const flowIncompleted = async (objetoInformacion, flag) => {
    let tipo = '';
    let informacion = {};
    let objeto = { createScore: true }
    finalUpdateScore(objeto).then(({ status, data: { payload } }) => {
        if (status === 200) {
            informacion.status = status;
            informacion.payload = payload;
            evento('Actualizar Score', 'Success', informacion, true);
        }
        sendStatusBlocked(objetoInformacion, flag);
    }).catch(error => {
        if (error.response) {
            let { data: { message }, status } = error.response
            informacion.mensaje = message;
        }
        evento('Envío Zip Trunco', tipo, informacion, false);
    });
}
        
export const sendZipFlag = async (tipoEnvio, bandera, isIOS) => {
    let objeto = {};
    let tipo = '';
    let informacion = {};
    let uuid = '';
    let uuidTrx = '';
    let dataUser = null;
    let dataOtorgante = [];
    let uuidUser = localStorage.getItem("uuidUser")
    if (uuidUser) {
        uuid = uuidUser;
    }
    let uuidTrxLS = localStorage.getItem("uuidTrx")
    if (uuidTrxLS) {
        uuidTrx = uuidTrxLS;
    }
    let dataUserLS = localStorage.getItem("data_user")
    if (dataUserLS) {
        dataUser = JSON.parse(dataUserLS);
    }
    let dataOtorganteLS = localStorage.getItem("dataOtorgante")
    if (dataOtorganteLS) {
        dataOtorgante = JSON.parse(dataOtorganteLS);
    }
    objeto.tipo_envio = tipoEnvio;
    objeto.semaforo = bandera;
    objeto.uuidUser = uuid;
    objeto.name = dataUser ? obtenerValorUser(dataUser, "nombreCompleto") : '';
    objeto.email = '';
    objeto.fNac = dataUser ? obtenerValorUser(dataUser, "nacimiento") : '';
    objeto.curp = dataUser ? (hayExcepcion()) ? obtenerValorUser(dataUser, "dni") : obtenerValorUser(dataUser, "curp") : '';
    objeto.vigencia = dataUser ? obtenerValorUser(dataUser, "vigencia") : '';
    objeto.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {};
    objeto.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {};
    objeto.data_user = JSON.parse(localStorage.getItem("data_user")) || [];
    let fecha = ""
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    if (month < 10) {
        fecha = `${day}0${month}${year}`
    } else {
        fecha = `${day}${month}${year}`
    }
    let partner = obtenerValorConfig(dataOtorgante, "PARTNER", "")
    partner = partner.replace(" ", "_");
    if (partner !== "") {
        partner = partner + "_"
    }
    let name_otorgante = obtenerValorConfig(dataOtorgante, "NOMBRE_CORTO", "")
    name_otorgante = name_otorgante.replace(" ", "_");
    objeto.custom_uuid = partner + name_otorgante + "_" + fecha + "_" + uuidTrx.split("-")[0];
    objeto.originOS = isIOS ? 'ios' : 'android';
    objeto.coincidencia = "Requiere validación";
    enviarCorreoAceptacion(objeto).then((response) => {
        if (response.status === 200) {
            informacion.status = response.status;
            informacion.payload = response.data.payload;
            evento('Envío Zip Bandera', 'Success', informacion, true);
        }
    }).catch((error) => {
        if (error.response) {
            let { data: { message_client }, status } = error.response
            informacion.mensaje = message_client;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento('Envío Zip Bandera', tipo, informacion, false);
    })
}

export const sendZip = async (tipoEnvio, bandera) => {
    let dataJSONr = await convertdataJSONZip();
    let tipo = '';
    let informacion = {};
    dataJSONr.tipo_envio = tipoEnvio;
    dataJSONr.semaforo = bandera;
    generarZip(dataJSONr).then((response) => {
        if (response.status === 200) {
            informacion.status = response.status;
            informacion.payload = response.data.payload;
            evento('Envío Zip Trunco', 'Success', informacion, true);
        }
    }).catch((error) => {
        if (error.response) {
            let { data: { message_client }, status } = error.response
            informacion.mensaje = message_client;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento('Envío Zip Trunco', tipo, informacion, false);
    });
}

const convertdataJSONZip = () => {
    let dataOtorgante = null;
    let uuid = '';
    let uuidTrx = '';
    let uuidUser = localStorage.getItem("uuidUser")
    if (uuidUser) {
        uuid = uuidUser;
    }
    let uuidTrxLS = localStorage.getItem("uuidTrx")
    if (uuidTrxLS) {
        uuidTrx = uuidTrxLS;
    }
    let dataOtorganteLS = localStorage.getItem("dataOtorgante")
    if (dataOtorganteLS) {
        dataOtorgante = JSON.parse(dataOtorganteLS);
    }
    let correo = localStorage.getItem("correoElectronico") || '';
    var fecha = ""
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    if (month < 10) {
        fecha = `${day}0${month}${year}`
    } else {
        fecha = `${day}${month}${year}`
    }
    var jsonObj = {};
    jsonObj.uuidUser = uuid;
    jsonObj.uuidTrx = uuidTrx;
    let partner = obtenerValorConfig(dataOtorgante, "PARTNER", "")
    partner = partner.replace(" ", "_");
    if (partner !== "") {
        partner = partner + "_"
    }
    let name_otorgante = obtenerValorConfig(dataOtorgante, "NOMBRE_CORTO", "")
    name_otorgante = name_otorgante.replace(" ", "_");
    let uuidC = partner + name_otorgante + "_" + fecha + "_" + uuid.split("-")[0];
    jsonObj.custom_uuid = uuidC;
    jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {};
    jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {};
    jsonObj.data_user = JSON.parse(localStorage.getItem("data_user")) || [];
    jsonObj.links = JSON.parse(localStorage.getItem('links')) || {};
    jsonObj.email = correo;
    return jsonObj;
}

export const sendImage = async (imagen, modelo, nombre, tipoImagen, lado) => {
    let tipo = '';
    let informacion = {};
    enviarImagenEvaluar(imagen, modelo, nombre, lado).then(({ status, data: { payload } }) => {
        if (status === 200) {
            informacion.status = status;
            informacion.payload = payload;
            evento(tipoImagen, 'Success', informacion, true);
        }
    }).catch((error) => {
        if (error.response) {
            let { data: { message_client }, status } = error.response
            informacion.mensaje = message_client;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento(tipoImagen, tipo, informacion, false);
    })
}