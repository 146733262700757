/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import 'webrtc-adapter';
import { configStyle, obtenerValorConfig } from '../services/configStyle';
import Loader from "../components/loader";
import LoaderOCR from "../components/loaderOCR";
import { obtenerOCR, obtenerParametrosOtorgante } from '../services/api.js';
import { isIOS, isMobileSafari, isMobile, withOrientationChange, isIPad13, deviceDetect } from 'react-device-detect';
import ConnectionClient from '../services/ConnectionClientWebRTC';
import { hayExcepcion, status, statusError, statusData, statusReintento, initStatus, evento, flowIncompleted, enviarObjetoATD, statusBlocked, sendZipFlag, sendImage } from '../services/data';
import { v4 as uuidv4 } from 'uuid';

const bucket = process.env.REACT_APP_BUCKET;

class CameraPhotoIOS extends Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };
    


    state = {
        loading: false,
        uuid: null,
        dataUser: [],
        dataOtorgante: [],
        dataOCRFront: {},
        isStart: true,
        side: "front",
        proccessing: false,
        proccessingHolo: false,
        desktop: false,
        altura: 960,
        ancho: 720,
        showIdOverlay: false,
        intentsFront: 0,
        intentsBack: 0,
        apikey: "",
        hasResponse: false,
        hasFace: false,
        hasTextFront: false,
        hasTextBack: false,
        isPassport: true,
        isHoloInstructions: false,
        showHoloAnimation: true,
        intentsData: 0,
        errorF: "",
        errorMsg: false,
        errorVideo: false,
        streamStarted: false,
        pathname: "",
        nombreOtorgante: '',
        uuidTrx: '',
        errorMessage: '',
        btnCancelar: false,
    }

    componentDidMount() {
        const component = this;
        // const apikey = 'v58hR5kiAbGhwdKg8zJ2UeF3hpfwJMA9';
        const {apikey} = this.props.match.params;
        const hayCookies = navigator.cookieEnabled;

        const itemsDeleteLS = [
            "codes",
            "ocrFront",
            "ocrBack",
            "time",
            "bloqueo",
            "face",
            "fotos",
            "enlace",
            "data_user",
            "movil",
            "dataOtorgante",
            "apikeyOtorgante",
            "uuidTrx",
            "fechaVigencia",
            "uuidOtorgante",
            "fields",
            "links",
            "flag"
        ];
        //console.log("Version 1.04.27");
        if (hayCookies) {
            let uuidUser = localStorage.getItem("uuidUser");
            if (uuidUser === null) {
                if (new URL(window.location).searchParams.get("cliente")) {
                    localStorage.setItem("uuidUser", new URL(window.location).searchParams.get("cliente"))
                } else {
                    let newUuid = uuidv4();
                    localStorage.setItem("uuidUser", newUuid);
                }
            } else {
                if (new URL(window.location).searchParams.get("cliente")) {
                    localStorage.setItem("uuidUser", new URL(window.location).searchParams.get("cliente"))
                }
            }
            let reintento = localStorage.getItem('reintentos');
            console.log("Reintentos", reintento);
            if ( !reintento) {
                itemsDeleteLS.forEach((element, indice) => {
                    localStorage.removeItem(element);
                });
                if (new URL(window.location).searchParams.get("uuidTrx")) {
                    console.log("hay uuidTrx");
                    localStorage.setItem("uuidTrx", new URL(window.location).searchParams.get("uuidTrx"))
                } else {
                    let trx_id = uuidv4();
                    localStorage.setItem("uuidTrx", trx_id);
                }
                let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
                if (apikeyOtorgante !== null) {
                    this.setState({ apikey: apikeyOtorgante });
                } else {
                    this.setState({ apikey: apikey })
                }
                localStorage.setItem("apikeyOtorgante", apikey)
                this.obtenerParametros(hayCookies);
            } else {
                localStorage.removeItem('reintentos')
            }
            if (new URL(window.location).searchParams.get("scheme")) {
                console.log("hay uuidTrx");
                localStorage.setItem("package", new URL(window.location).searchParams.get("scheme"))
            }
            
        }

        localStorage.setItem("completado", "false");

        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            this.setState({ uuid: uuidUser })
        }

        let uuidTrx = localStorage.getItem("uuidTrx")
        if (uuidTrx) {
            this.setState({ uuidTrx: uuidTrx })
        }

        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            this.setState({ dataUser: JSON.parse(dataUserLS) })
        }

        let bloqueo = localStorage.getItem("bloqueo");
        if (bloqueo === null) {
            localStorage.setItem("bloqueo", "false");
        } else {
            if (bloqueo == 'true') {
                this.state.intentsFront = Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3"));
                //console.log("intentsFront: " + this.state.intentsFront);
            }
        }
        this.state.pathname = window.location.pathname;
        //console.log("bloque: " + bloqueo);
        if (!isMobile) {
            //console.log("es desktop");
            this.state.desktop = true;
            this.state.altura = 720;
            this.state.ancho = 960;
            /*  var element = document.getElementById("myVideo");
             element.classList.add("myVideo_rotate"); */
            this.setState({ showHoloAnimation: false })
            this.setState({ isHoloInstructions: false, isStart: true })
            var element = document.getElementById("video_wrt");
            element.classList.add("myVideo_rotate");
        } else {
            if (hayExcepcion()) {
                this.setState({ isHoloInstructions: false, isStart: true, showHoloAnimation: false })
            }
        }

        this.state.isPassport = false;
        /*
        if(this.state.isPassport){
            var jsonObj = {"type_id":"PASAPORTE"};
            statusData("captura_id", jsonObj)
            //console.log("pasaporte");
        }else{
            var jsonObj = {"type_id":"INE"};
            statusData("captura_id", jsonObj)
            //console.log("ine");
        }
        */
        var deviceFuncional = "";
        navigator.mediaDevices.enumerateDevices().then(function (devices) {
            for (var i = 0; i < devices.length; i++) {
                var device = devices[i];
                if (device.kind === 'videoinput') {
                    //console.log(device.label)
                    if ((device.label).includes("back")) {
                        if ((device.label).includes("0")) {
                            deviceFuncional = device.deviceId;
                            //console.log("deviceFuncional1", deviceFuncional);
                        }
                    }
                }
            }
            component.start(deviceFuncional);
        });
        //console.log("deviceFuncional2", deviceFuncional);
        //status("captura_id_page", "Captura ID")
        
        evento('Dispositvo', 'Step', deviceDetect(), true);
        evento('Dimensiones viewport', 'Información', { innerWidth: window.innerWidth, innerHeight: window.innerHeight }, true);
    }

    start = (deviceFuncional) => {

        const that = this;
        if (window.stream) {
            //console.log("cerrando");
            window.stream.getTracks().forEach(track => {
                track.stop();
            });
        }
        const constraints = {
            audio: false,
            video: (deviceFuncional) ? {
                deviceId: { exact: deviceFuncional },
                width: { ideal: 640 },
                height: { ideal: 480 },
                advanced: [
                    { width: 1280, height: 720 },
                    { aspectRatio: 4 / 3 }
                ]
            } : {
                facingMode: "environment",
                width: { ideal: 640 },
                height: { ideal: 480 },
                advanced: [
                    { width: 1280, height: 720 },
                    { aspectRatio: 4 / 3 }
                ]
            }
        };

        //console.log("Reconfiguracion",constraints);
        that.localStream = navigator.mediaDevices.getUserMedia(constraints)
            .catch((error) => {
                if (error.name == "NotFoundError" || error.name == "DevicesNotFoundError") {
                    //required track is missing 
                    evento('Captura ID', 'User Media', { error: error.name, status: 'NO SE ENCONTRO DISPOSITIVO Y/O TRACK' }, true);
                } else if (error.name == "NotReadableError" || error.name == "TrackStartError") {
                    //webcam or mic are already in use 
                    evento('Captura ID', 'User Media', { error: error.name, status: 'LOS DISPOSITVOS SOLICITADOS ESTÁN EN USO' }, true);
                } else if (error.name == "OverconstrainedError" || error.name == "ConstraintNotSatisfiedError") {
                    //constraints can not be satisfied by avb. devices 
                    evento('Captura ID', 'User Media', { error: error.name, status: 'EL DISPOSITIVO NO PUEDE ALCANZAR LOS CONSTRAINTS' }, true);
                } else if (error.name == "NotAllowedError" || error.name == "PermissionDeniedError") {
                    //permission denied in browser 
                    evento('Captura ID', 'User Media', { error: error.name, status: 'PERMISOS DENEGADOS' }, true);
                } else if (error.name == "TypeError" || error.name == "TypeError") {
                    //empty constraints object 
                    evento('Captura ID', 'User Media', { error: error.name, status: 'CONSTRAINTS VACÍOS' }, true);
                } else {
                    //other errors 
                    evento('Captura ID', 'User Media', { error: error.toString(), status: 'OTRO TIPO DE ERROR' }, true);
                }
                that.setState({ errorVideo: true })

            }
            );

        that.canvas = document.querySelector('canvas');
        that.width = 320;
        that.height = 0;
        that.video = document.querySelector('video');
        that.elemento = document.getElementById("cuadro");
        that.cuenta = document.getElementById("numeros");
        that.titulo = document.getElementById("head_shop");
        let component = that;
        that.localStream.then(function (mediaStream) {
            component.video.srcObject = mediaStream;
            component.video.onloadedmetadata = function (e) {
                component.video.play();
                if (mediaStream.getVideoTracks().length > 0) {
                    //console.log("disponibles");
                    component.setState({ streamStarted: true, errorVideo: false })
                } else {
                    //console.log("No disponibles");    
                    component.setState({ errorVideo: true })
                }
            };
            component.video.addEventListener('canplay', function (ev) {
                component.height = component.video.videoHeight / (component.video.videoWidth / component.width);
                component.canvas.setAttribute('width', component.video.videoWidth * 1.5);
                component.canvas.setAttribute('height', component.video.videoHeight * 1.5);
            }, false);
        }).catch(e => {
            console.trace(e);
            that.setState({ errorVideo: true })
        })

    }

    cancelarCapturaIdentificacion = async() => {
        let data = "Cancelado";
        localStorage.setItem('flag', 'cancelado');
        await statusError("Captura ID", data, "cancelado");
        this.setState({ proccessingHolo: true })
        evento('Captura ID', 'Click', { description: 'CANCELAR' }, true);
        this.setState({ proccessingHolo: true })
        setTimeout(() => {
            this.setState({ proccessingHolo: false })
            window.location.reload();
        }, 10000);
    }

    enviarFotoHolograma = async () => {
        this.setState({ loading: true, hasResponse: false })
        const that = this
        const connectionClient = new ConnectionClient();
        let peerConnection = null;
        const localVideo = document.getElementById('video_wrt');
        localVideo.autoplay = true;
        localVideo.muted = true;
        async function beforeAnswer(peerConnectionH) {
            that.localStream.then(function (mediaStream) {
                mediaStream.getTracks().forEach(track => peerConnectionH.addTrack(track, mediaStream));
            })
            peerConnection = peerConnectionH;
            let dataChannel = null;
            let interval = null;
            let timeoutt = null
            function onMessage({ data }) {
                const responseWRT = JSON.parse(data);
                //console.log(responseWRT);
                peerConnectionH.close()
                that.setState({ isHoloInstructions: false, proccessingHolo: false })
                that.setState({ showHoloAnimation: false })
            }
            let intentos = 0
            function onDataChannel({ channel }) {
                if (channel.label !== 'ping-pong') {
                    return;
                }
                dataChannel = channel;
                dataChannel.addEventListener('message', onMessage);
                interval = setInterval(() => {
                    intentos++;
                    if (intentos === 5) {
                        that.setState({ proccessingHolo: true, loading: false })
                        dataChannel.send("finish_record");
                    } else if (intentos === 60) {
                        that.setState({ isHoloInstructions: false, proccessingHolo: false })
                        peerConnectionH.close()
                    }
                }, 1000);
                timeoutt = setTimeout(() => {
                    dataChannel.send(JSON.stringify({ uuid: that.state.uuid, side: that.state.side, bucket: bucket, ios: isIOS, mobilesafari: isMobileSafari, isIPad: isIPad13, type: "HOLOGRAMA", isMobile: isMobile, isPassport: (that.state.isPassport).toString(), key: localStorage.getItem("apikeyOtorgante"), transaccion: (localStorage.getItem("uuidTrx")), cliente: (localStorage.getItem("uuidUser")) }));
                }, 500);
            }
            peerConnectionH.addEventListener('datachannel', onDataChannel);
            const { close } = peerConnectionH;
            peerConnectionH.close = function () {
                if (dataChannel) {
                    dataChannel.removeEventListener('message', onMessage);
                }
                if (interval) {
                    clearInterval(interval);
                }
                if (timeoutt) {
                    clearTimeout(timeoutt)
                }
                return close.apply(this, arguments);
            };
        }

        peerConnection = await connectionClient.createConnection({ beforeAnswer: beforeAnswer })
        window.peerConnection = peerConnection;

    }


    takeFotoFront = () => {
        const that = this;
        let tipo = '';
        let informacion = {};
        let nombre = '';
        let validacionOCR;
        that.setState({ proccessingHolo: true })
        setTimeout(() => {
            //console.log("tomando foto");
            that.setState({ proccessing: true, loading: false, proccessingHolo: false })
            let contextCanvas = this.canvas.getContext('2d');
            contextCanvas.drawImage(this.video, 0, 0, this.video.videoWidth * 1.5, this.video.videoHeight * 1.5);
            this.canvas.toBlob((blob) => {
                //obtenerOCR(uuid, imagen, side, isIOS, isMobileSafari)
                nombre = that.state.side + "-" + (new Date().getTime()) + ".png";
                sendImage(blob, 'ine', nombre, 'Evaluación Imagen Frente', that.state.side);
                obtenerOCR(that.state.uuid, blob, "front", isIOS, isMobileSafari)
                    .then((response) => {
                        if (response.status === 200) {
                            //console.log("200","nuevoOCR");
                            informacion.status = response.status;
                            informacion.payload = response.data.payload;
                            informacion.side = that.state.side;
                            validacionOCR = response.data.payload.ocrData.ocrFrontIsNotBlank;
                            evento('Captura ID', 'Success', informacion, true);
                            if (validacionOCR) {
                                let fechaFinal = response.data.payload.ocrData.fechaNacimiento
                                let fechaOcr = '';
                                if (fechaFinal) {
                                    if (fechaFinal.length > 6 && fechaFinal.length < 10) {
                                        let añoActualInt = new Date().getFullYear().toString().substr(-2);
                                        let añoFechaOcr = fechaFinal.substr(-2);
                                        let añoFechaOcrInt = fechaFinal.substr(-2);
                                        let fechaSinAño = fechaFinal.substr(0, 6);
                                        if (añoFechaOcrInt >= añoActualInt) {
                                            fechaOcr = fechaSinAño + '19' + añoFechaOcr;
                                        } else {
                                            let mayoriaDeEdad = añoActualInt - 18;
                                            if (añoFechaOcrInt <= mayoriaDeEdad) {
                                                fechaOcr = fechaSinAño + '20' + añoFechaOcr;
                                            } else {
                                                fechaOcr = fechaSinAño + '20' + añoFechaOcr;
                                            }
                                        }
                                    } else if (fechaFinal.length < 6) {
                                        fechaOcr = ""
                                    } else {
                                        fechaOcr = fechaFinal
                                    }
                                }
                                that.setState({
                                    dataOCRFront: {
                                        nombreCompleto: response.data.payload.ocrData.nombreCompleto,
                                        curp: response.data.payload.ocrData.curp,
                                        dni: response.data.payload.ocrData.dni,
                                        claveElector: response.data.payload.ocrData.claveElector,
                                        estado: response.data.payload.ocrData.estado,
                                        municipio: response.data.payload.ocrData.municipio,
                                        seccion: response.data.payload.ocrData.ineSeccion,
                                        colonia: response.data.payload.ocrData.colonia,
                                        calle: response.data.payload.ocrData.calle,
                                        cp: response.data.payload.ocrData.cp,
                                        numero: response.data.payload.ocrData.numero,
                                        anioRegistro: response.data.payload.ocrData.anioRegistro,
                                        numeroRegistro: response.data.payload.ocrData.numeroRegistro,
                                        ineRegistro: response.data.payload.ocrData.ineRegistro,
                                        emision: response.data.payload.ocrData.ineEmision,
                                        vigencia: response.data.payload.ocrData.ineVigencia,
                                        fechaNacimiento: fechaOcr,
                                        sexo: response.data.payload.ocrData.sexo,
                                        apellidoPaterno: response.data.payload.ocrData.apellidoPaterno,
                                        apellidoMaterno: response.data.payload.ocrData.apellidoMaterno,
                                        nombres: response.data.payload.ocrData.nombres,
                                        identificacionElectoral: response.data.payload.ocrData.identificacionElectoral,
                                        documentoNacionalPeru: response.data.payload.ocrData.documentoNacionalPeru,
                                        identificacionMigratoria: response.data.payload.ocrData.identificacionMigratoria,
                                        identificacionPasaporte: response.data.payload.ocrData.identificacionPasaporte,
                                    }
                                })
                                localStorage.setItem("ocrFront", JSON.stringify({
                                    nombreCompleto: response.data.payload.ocrData.nombreCompleto,
                                    curp: response.data.payload.ocrData.curp,
                                    dni: response.data.payload.ocrData.dni,
                                    claveElector: response.data.payload.ocrData.claveElector,
                                    estado: response.data.payload.ocrData.estado,
                                    municipio: response.data.payload.ocrData.municipio,
                                    seccion: response.data.payload.ocrData.ineSeccion,
                                    colonia: response.data.payload.ocrData.colonia,
                                    calle: response.data.payload.ocrData.calle,
                                    cp: response.data.payload.ocrData.cp,
                                    numero: response.data.payload.ocrData.numero,
                                    ineRegistro: response.data.payload.ocrData.ineRegistro,
                                    anioRegistro: response.data.payload.ocrData.anioRegistro,
                                    numeroRegistro: response.data.payload.ocrData.numeroRegistro,
                                    emision: response.data.payload.ocrData.ineEmision,
                                    vigencia: response.data.payload.ocrData.ineVigencia,
                                    fechaNacimiento: fechaOcr,
                                    sexo: response.data.payload.ocrData.sexo,
                                    apellidoPaterno: response.data.payload.ocrData.apellidoPaterno,
                                    apellidoMaterno: response.data.payload.ocrData.apellidoMaterno,
                                    nombres: response.data.payload.ocrData.nombres,
                                    identificacionElectoral: response.data.payload.ocrData.identificacionElectoral,
                                    documentoNacionalPeru: response.data.payload.ocrData.documentoNacionalPeru,
                                    identificacionMigratoria: response.data.payload.ocrData.identificacionMigratoria,
                                    identificacionPasaporte: response.data.payload.ocrData.identificacionPasaporte,
                                }))
                                //console.log("guarde");
                                var tipo = ""
                                if (that.state.dataOCRFront.identificacionElectoral) {
                                    tipo = "INE";
                                }
                                else if (that.state.dataOCRFront.identificacionPasaporte) {
                                    tipo = "PASAPORTE";
                                    that.setState({ isPassport: true });
                                }
                                else if (that.state.dataOCRFront.documentoNacionalPeru) {
                                    tipo = "DNI_PERU";
                                }
                                else if (that.state.dataOCRFront.identificacionMigratoria) {
                                    tipo = "FMM";
                                }
                                else {
                                    tipo = "INE";
                                }
                                var jsonObj = { "type_id": tipo };
                                localStorage.setItem('typeID', tipo.toLowerCase());
                                statusData("captura_id", jsonObj)
                                //console.log(("tipo",tipo));
                                if (tipo === "PASAPORTE") {
                                    that.setState({ loading: false, proccessing: false, hasTextBack: true })
                                    that.sendData()
                                    setTimeout(() => {
                                        that.props.history.push({
                                            pathname: '/resultados_identificacion',
                                            state: { passport: false }
                                        })
                                    }, 300);
                                } else {
                                    let dataOCRFrontLS = localStorage.getItem("ocrFront")
                                    const ocrFront = JSON.parse(dataOCRFrontLS)
                                    //console.log(ocrFront);
                                    if (ocrFront.nombreCompleto && (ocrFront.curp || ocrFront.dni)) {
                                        if (ocrFront.dni) {
                                            if (ocrFront.dni.length < 3 || ocrFront.nombreCompleto.length < 10) {
                                                if (that.state.intentsData === 2) {
                                                    localStorage.setItem("intentsData", "OCR");
                                                    that.setState({ loading: false, proccessing: false, side: "back", hasTextFront: true, intentsFront: 0, intentsData: 0 })
                                                } else {
                                                    that.setState({ loading: false, proccessing: false, intentsData: that.state.intentsData + 1, errorF: "Datos no cumplen con formato", hasResponse: true })
                                                    that.statusR();
                                                }
                                            } else {
                                                that.setState({ loading: false, proccessing: false, side: "back", hasTextFront: true, intentsFront: 0, intentsData: 0 })
                                            }
                                        } else {
                                            if (ocrFront.curp.length < 16 || ocrFront.nombreCompleto.length < 10) {
                                                if (that.state.intentsData === 2) {
                                                    localStorage.setItem("intentsData", "OCR");
                                                    that.setState({ loading: false, proccessing: false, side: "back", hasTextFront: true, intentsFront: 0, intentsData: 0 })
                                                } else {
                                                    that.setState({ loading: false, proccessing: false, intentsData: that.state.intentsData + 1, errorF: "Datos no cumplen con formato", hasResponse: true })
                                                    that.statusR();
                                                }
                                            } else {
                                                that.setState({ loading: false, proccessing: false, side: "back", hasTextFront: true, intentsFront: 0, intentsData: 0 })
                                            }
                                        }
                                    } else {
                                        if (that.state.intentsData === 2) {
                                            localStorage.setItem("intentsData", "OCR");
                                            that.setState({ loading: false, proccessing: false, side: "back", hasTextFront: true, intentsFront: 0, intentsData: 0 })
                                        } else {
                                            that.setState({ loading: false, proccessing: false, intentsData: that.state.intentsData + 1, errorF: "Datos incompletos", hasResponse: true })
                                            that.statusR();
                                        }
                                        ////console.log("intentsData",intentsData);
                                    }
                                }
                                if (that.state.intentsFront === Number(obtenerValorConfig(that.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3")) && that.state.hasFace) {
                                    if (tipo === "PASAPORTE") {
                                        that.setState({ loading: false, proccessing: false, hasTextBack: true })
                                        that.sendData()
                                        setTimeout(() => {
                                            that.props.history.push({
                                                pathname: '/resultados_identificacion',
                                                state: { passport: false }
                                            })
                                        }, 300);
                                    } else {
                                        that.setState({ loading: false, proccessing: false, side: "back" })
                                    }
                                }
                                that.setState({ loading: false, proccessing: false, hasResponse: true });

                            } else {
                                that.setState({ errorMessage: "No se pudo leer correctamente tus datos, por favor vuelve a intentar.", intentsFront: that.state.intentsFront + 1, errorCapture: true, errorF: "Error en la captura de identificación", token: null, loading: false, proccessing: false, hasResponse: true, capturing: false });
                                if (that.state.intentsFront === Number(obtenerValorConfig(that.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3")))
                                    that.finalizarFlujo(informacion);
                                else
                                    that.statusR();
                                statusReintento('Intentos Captura ID Frente', that.state.intentsFront, 'RETRY');
                            }
                            
                        } else {
                            //console.log("otreo");
                            that.setState({ loading: false, proccessing: false, intentsFront: that.state.intentsFront + 1, errorF: response.data.message, hasResponse: true })
                            if (that.state.intentsFront === Number(obtenerValorConfig(that.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3"))) {
                                that.finalizarFlujo(informacion);
                            } else {
                                that.statusR();
                            }
                            that.setState({ errorMsg: true })
                        }

                    })
                    .catch((error) => {
                        if (error.response) {
                            that.setState({ errorMessage: "No se pudo leer correctamente tus datos, por favor vuelve a intentar.", intentsFront: that.state.intentsFront + 1, errorCapture: true, errorF: "Error en la captura de la identificación", token: null, loading: false, proccessing: false, hasResponse: true })
                            let { data: { message_client }, status } = error.response
                            informacion.mensaje = message_client;
                            informacion.status = status;
                            tipo = 'Error';
                            statusReintento('Intentos Captura ID Frente', that.state.intentsFront, 'RETRY');
                        } else {
                            if (error.toString().includes('Network')) {
                                that.setState({ errorMessage: "Revisa tu conexión a internet e intenta nuevamente.", errorCapture: true, errorF: "Error en la captura de la identificación", token: null, loading: false, proccessing: false, hasResponse: true })
                            }else{
                                that.setState({ errorMessage: "No se pudo leer correctamente tus datos, por favor vuelve a intentar.", errorCapture: true, errorF: "Error en la captura de la identificación", token: null, loading: false, proccessing: false, hasResponse: true })
                            }
                            informacion.error = error.toString();
                            tipo = 'Exception';
                        }
                        informacion.side = that.state.side;
                        evento('Captura ID', tipo, informacion, false);
                        if (that.state.intentsFront === Number(obtenerValorConfig(that.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3"))) {
                            that.finalizarFlujo(informacion);
                        } else {
                            that.statusR();
                        }
                    })
            }, 'image/png', 0.9);
        }, 1350);
    }

    takeFotoBack = () => {
        let tipo = '';
        let informacion = {};
        const that = this;
        let nombre = '';
        let validacionOCR;
        // that.setState({ proccessing: true, loading: false })
        that.setState({ proccessingHolo: true })
        setTimeout(() => {
            //console.log("tomando foto back");
            let contextCanvas = this.canvas.getContext('2d');
            contextCanvas.drawImage(this.video, 0, 0, this.video.videoWidth * 1.5, this.video.videoHeight * 1.5);
            this.canvas.toBlob((blob) => {
                //obtenerOCR(uuid, imagen, side, isIOS, isMobileSafari)
                that.setState({ proccessing: true, loading: false, proccessingHolo: false })
                nombre = that.state.side + "-" + (new Date().getTime()) + ".png";
                sendImage(blob, 'ine', nombre, 'Evaluación Imagen Back', that.state.side);
                obtenerOCR(that.state.uuid, blob, "back", isIOS, isMobileSafari)
                    .then((response) => {
                        if (response.status === 200) {
                            informacion.status = response.status;
                            informacion.payload = response.data.payload;
                            informacion.side = that.state.side;
                            validacionOCR = response.data.payload.ocrData.ocrBackIsNotBlank;
                            if (validacionOCR) {
                                that.setState({
                                    dataOCRBack: {
                                        cadena: response.data.payload.ocrData.cadena,
                                        ineVigencia: response.data.payload.ocrData.ineVigencia,
                                        cicMRZ: response.data.payload.ocrData.cicMRZ,
                                        ciudadanoMRZ: response.data.payload.ocrData.ciudadanoMRZ,
                                        nombreCompletoMRZ: response.data.payload.ocrData.nombreCompletoMRZ,
                                        seccionMRZ: response.data.payload.ocrData.seccionMRZ
    
                                    }
                                })
                                localStorage.setItem("ocrBack", JSON.stringify({
                                    cadena: response.data.payload.ocrData.cadena,
                                    ineVigencia: response.data.payload.ocrData.ineVigencia,
                                    cicMRZ: response.data.payload.ocrData.cicMRZ,
                                    ciudadanoMRZ: response.data.payload.ocrData.ciudadanoMRZ,
                                    nombreIntegroMRZ: response.data.payload.ocrData.nombreCompletoMRZ,
                                    seccionMRZ: response.data.payload.ocrData.seccionMRZ
                                }))
                                //console.log("guarde");
                                that.setState({ loading: false, proccessing: false, hasTextBack: true })
                                that.sendData();
                                setTimeout(() => {
                                    that.props.history.push({
                                        pathname: '/resultados_identificacion',
                                        state: { passport: false }
                                    })
                                }, 300);
                            } else {
                                that.setState({ errorMessage: "No se pudo leer correctamente tus datos, por favor vuelve a intentar.", intentsBack: that.state.intentsBack + 1, errorCapture: true, errorF: "Error en la captura de identificación", token: null, loading: false, proccessing: false, hasResponse: true, capturing: false });
                                if (that.state.intentsBack === Number(obtenerValorConfig(that.state.dataOtorgante, "INTENTOS_ID_REVERSO", "3"))) {
                                    that.sendData()
                                    setTimeout(() => {
                                        that.props.history.push({
                                            pathname: '/resultados_identificacion',
                                            state: { passport: false }
                                        })
                                    }, 300);
                                } else
                                    that.statusR();
                                statusReintento('Intentos Captura ID Reverso', that.state.intentsBack, 'RETRY');
                            }
                            evento('Captura ID', 'Success', informacion, true);
                            //console.log("200-back",response.data);
                        } else {
                            that.setState({ loading: false, proccessing: false, intentsBack: that.state.intentsBack + 1, errorF: "Error en la captura", hasResponse: true })
                            that.statusR();
                            if (that.state.intentsBack === Number(obtenerValorConfig(that.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3"))) {
                                that.sendData()
                                setTimeout(() => {
                                    that.props.history.push({
                                        pathname: '/resultados_identificacion',
                                        state: { passport: false }
                                    })
                                }, 300);
                            }
                        }

                    })
                    .catch((error) => {
                        if (error.response) {
                            that.setState({ errorMessage: "No se pudo leer correctamente tus datos, por favor vuelve a intentar.", errorCapture: true, errorF: "Error en la captura de identificación", token: null, proccessing: false, loading: false, hasResponse: true, capturing: false });
                            let { data: { message_client }, status } = error.response
                            informacion.mensaje = message_client;
                            informacion.status = status;
                            tipo = 'Error';
                            if (status === 400) {
                                that.setState({ intentsBack: that.state.intentsBack + 1 })
                                statusReintento('Intentos Captura ID Reverso', that.state.intentsBack, 'RETRY');
                            }
                        } else {
                            if (error.toString().includes('Network')) {
                                that.setState({ errorMessage: "Revisa tu conexión a internet e intenta nuevamente.", errorCapture: true, errorF: "Error en la captura de identificación", token: null, proccessing: false, loading: false, hasResponse: true })
                            }else{
                                that.setState({ errorMessage: "No se pudo leer correctamente tus datos, por favor vuelve a intentar.", errorCapture: true, errorF: "Error en la captura de identificación", token: null, proccessing: false, loading: false, hasResponse: true })
                            }
                            informacion.error = error.toString();
                            tipo = 'Exception';
                        }
                        informacion.side = that.state.side;
                        evento('Captura ID', tipo, informacion, false);
                        if (that.state.intentsBack === Number(obtenerValorConfig(that.state.dataOtorgante, "INTENTOS_ID_REVERSO", "3"))) {
                            that.sendData()
                            setTimeout(() => {
                                that.props.history.push({
                                    pathname: '/resultados_identificacion',
                                    state: { passport: false }
                                })
                            }, 300);
                        }
                        else
                            that.statusR();
                    })
            }, 'image/png', 0.9);
        }, 1350);
    }

    initRecord = (side) => {
        let description = (side === 'front') ? 'TOMAR FOTO(FRENTE)' : 'TOMAR FOTO(REVERSO)';
        this.setState({ side: side, loading: true })
        evento('Captura ID', 'Click', { description: description }, true);
        if (side === "front") {
            //this.enviarFotoFront()
            this.takeFotoFront()
        } else {
            //this.enviarFotoBack()
            this.takeFotoBack();
        }
    }

    initRecordHologram = (side) => {
        //console.log("recording for hologram");
        this.enviarFotoHolograma();
        this.setState({ showHoloAnimation: true })
        tipo = 'Success';
        evento('Inicio de grabación de holograma', tipo, informacion, true);
    }
    // destroy player on unmount
    componentWillUnmount() {
        if (this.localStream) {
            this.localStream.then(function (mediaStream) {
                if(mediaStream) mediaStream.getTracks().forEach(track => track.stop());
            });
            this.localStream = null;
        }
        window.removeEventListener("beforeunload", () => {//console.log("Se quito componente video");
        });
    }

    sendData = () => {
        var jsonObj = {};
        jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront"));
        jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack"));
        statusData("captura_id_ocr", jsonObj)
        //var res = JSON.stringify(jsonObj)
    }

    statusF = () => {
        //console.log("entre a status ine");
        let id = (this.state.isPassport) ? "Pasaporte" : "INE"
        statusError("Captura ID", id + ":" + this.state.errorF, "error")
    }

    intentosBloqueo = () => {
        localStorage.setItem("bloqueo", "true");
        let id = (this.state.isPassport) ? "Pasaporte" : "INE"
        statusError("Captura ID", id + ":" + this.state.errorF, "cancelado")
        let newDate = new Date().getTime();
        let acceptDta = new Date();
        acceptDta.setTime(newDate + (60 * 60 * 1000))
        localStorage.setItem("time", acceptDta.getTime())
        //console.log("BLOQUEO: " + localStorage.getItem("bloqueo"));
        //console.log("BLOQUEO: " + localStorage.getItem("bloqueo"));
    }

    checkCamera = () => {
        setTimeout(() => {
            //console.log("Camera:"+this.state.streamStarted);
            if (!this.state.streamStarted) {
                this.setState({ errorVideo: true })
            }
        }, 3500);
    }

    statusR = () => {
        statusReintento("Captura ID", this.state.errorF, "RETRY");
    }

    obtenerParametros = async (hayCookies) => {
        let tipo = '';
        let informacion = {};
        //setLoading(true)
        obtenerParametrosOtorgante(this.state.apikey).then((data) => {
            if (data.status === 200) {
                informacion.status = data.status;
                informacion.payload = data.data.payload;
                evento('Obtener Parametros Otorgante', 'Success', informacion, true);
                this.setState({ dataOtorgante: data.data.payload });
                configStyle(data.data.payload);
                if (hayCookies) {
                    localStorage.setItem("dataOtorgante", JSON.stringify(data.data.payload))
                }
                let ID_OTORGANTE = (obtenerValorConfig(data.data.payload, 'ID_OTORGANTE', ""));
                if (ID_OTORGANTE !== "") {
                    localStorage.setItem("uuidOtorgante", ID_OTORGANTE)
                }
            }
            //setLoading(false)
        }).catch((error) => {
            //console.log("algun error");
            //setLoading(false)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Obtener Parametros Otorgante', tipo, informacion, false);

        })
    }

    reload = () => {
        window.location.reload();
    }

    finalizarFlujo = async (response) => {
        await sendZipFlag('SEMAFORO', 'red', isIOS);
        this.setState({ errorCapture: false });
        localStorage.setItem("bloqueo", "true");
        let newDate = new Date().getTime();
        let acceptDta = new Date();
        acceptDta.setTime(newDate + (60 * 60 * 1000))
        localStorage.setItem("time", acceptDta.getTime())
        let res = this.convertdataJSON();
        let event_t = await JSON.stringify(res);
        var json_final = {};
        let status = "BLOCK"
        json_final = {
            "status": status,
            "step": "finished",
            "description": event_t,
            'response': response,
            "finished": true
        }
        let event = JSON.stringify(json_final);
        await enviarObjetoATD(false, true);
        await flowIncompleted({ 'step': 'captura_id', 'event': event }, 'red');
    }

    convertdataJSON = () => {
        let jsonObj = {};
        jsonObj.uuidTrx = localStorage.getItem("uuidTrx") || "";
        jsonObj.uuidUser = localStorage.getItem("uuidUser") || '';
        jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {};
        jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {};
        jsonObj.data_user = JSON.parse(localStorage.getItem("data_user")) || {};
        jsonObj.links = JSON.parse(localStorage.getItem('links')) || {};
        return jsonObj;
    }

    sendInitStatus = async() => {
        evento('Captura ID', 'Click', { description: 'DE ACUERDO' }, true);
        let nameOtorgante = obtenerValorConfig(this.state.dataOtorgante, 'NOMBRE_CORTO', "");
        await initStatus(nameOtorgante);
        status("captura_id_page", "Captura ID");
    }

    statusSE = () => {
        let data = "Reintento de captura de identificación";
        statusReintento("Captura ID", data, "RETRY");
        // if (window.opener) {
        //     setTimeout(() => {
        //         window.close();
        //     }, 300);
        // }
    }

    render() {
        const { isLandscape } = this.props;
        //if (isLandscape) { //console.log("landscape"); }
        return (
            <div>
                {(this.state.btnCancelar) ?
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                       <div className="modal-content">
                           <div className="modal-header">
                                <h5 className="modal-title">Cancelar proceso</h5>
                                <button onClick={e => this.setState({ btnCancelar: false })} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                           </div>
                           <div className="modal-footer">
                               <button onClick={e => {this.setState({ btnCancelar: false }) }} className="btn btn-secondary modal_btn_w" data-dismiss="modal">NO</button>
                                <button type="button" onClick={e => this.cancelarCapturaIdentificacion() } className="btn btn-raised btn-secondary modal_btn_w">SI</button>
                            </div>
                        </div>
                    </div>
                </div> : ("")}
                {(this.state.isStart && !this.state.isHoloInstructions) ? <Fragment>
                    <div className="overlay_box">
                        <div className="alert_box_bottom animated slideInDown">
                            <div className="background_atd">
                                <div className="padding_5">
                                    {(this.state.isPassport) ? (this.state.desktop) ? <p className="animated slideInDown">Por favor coloca la parte de <b>abajo</b> de tu pasaporte, mantenla <b>enfocada</b> dentro de la <b>guía</b> y sigue las instrucciones.</p>
                                        : <div>
                                            <div className="center_info_bx">
                                                <p className="centerText">
                                                    <img src={process.env.PUBLIC_URL + '/images/pass_scan.svg'} height="100" alt="" />
                                                    <span className="izquierdaText">
                                                        <br />
                                                        Sólo necesitas escanear la sección baja de la identificación que contiene tus datos personales.
                                                        <br />
                                                        <small>Procura no obstruir ningún dato o tu fotografía</small>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        : (this.state.desktop) ? <>
                                            <p className="alert_title"><b>Comencemos</b></p>
                                            <p className="alert_text">Coloca el frente de tu identificación, mantenla enfocada dentro de la guía y sigue las instrucciones.</p>
                                        </>
                                            : <>
                                                <p className="alert_title"><b>Comencemos</b></p>
                                                <p className="alert_text">Coloca tu identificación sobre una superficie oscura y sigue las instrucciones.</p>
                                            </>
                                    }
                                    <button type="button" onClick={(e) => {
                                        this.setState({ isStart: false });
                                        this.setState({ showIdOverlay: this.state.desktop ? true : false });
                                        this.checkCamera();
                                        this.sendInitStatus();
                                    }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_button border_button_20  animated fadeIn delay-2s">DE ACUERDO</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment> : ("")}


                <div className="module_container overflow_hddn desk_id_capture" style={{ position: 'fixed' }}>
                    <div className="camera_capture_frame" style={{ height: '112%' }}>
                        <video id="video_wrt" playsInline></video>
                        <canvas id="canvas_wrt_environment" hidden></canvas>
                    </div>
                    <div className="module_gradient_overlay"></div>
                    {/*(this.state.isHoloInstructions) ? <div><div className="module_title animated slideInDown">
                        <p>Vamos a <b>Calibrar</b> la cámara</p>
                    </div>
                        <div>
                            <img className="ine_mask_cel animated fadeIn delay-2s" src="/images/id_mask_capture_hollo.svg" alt="" />
                        </div> </div> : <div className="module_title animated slideInDown">
                        <p>Coloca el <b>{this.state.side === "front" ? "frente" : "reverso"}</b> de tu identificación</p>
                        </div>*/}
                    <div className="module_title animated slideInDown">
                        {this.state.side === "front" ? <p className="alert_text">Coloca el frente de tu identificación y dentro de la guía</p> :
                            <p className="alert_text">Ahora captura el reverso sin reflejos de luz</p>}
                    </div>
                    {(this.state.isHoloInstructions) ? ("") : (isMobile) ? <img className="ine_mask_cel animated fadeIn delay-2s" src={process.env.PUBLIC_URL + '/images/id_mask_capture_a.svg'} alt="" /> : <img className="ine_mask id_mask animated fadeIn delay-2s" src={process.env.PUBLIC_URL + '/images/id_mask.svg'} alt="" />}
                    <div className="module_icon_container">
                    </div>
                </div>
                {(this.state.isHoloInstructions ? ("") : <div className="alert_instruction animated fadeIn delay-1s alert_light">
                    {this.state.side === "front" &&
                        <p className="alert_text_bold">Evita las reflejos de luz</p>}
                </div>)}
                {/*(this.state.showHoloAnimation ? <div className="alert_instruction animated fadeIn delay-1s alert_light">
                    <img src={process.env.PUBLIC_URL + 'images/hollo_animation.gif'} height="100" alt="" />
                    </div> : (""))*/}

                {(this.state.isHoloInstructions) ? <div className="module_buttons animated fadeIn delay-3s"> <button type="button" onClick={() => this.initRecordHologram("front")} className={["btn btn-raised btn-primary forcewidth100 main_bg_color border_button_20", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} disabled={this.state.proccessingHolo || this.state.loading}>{this.state.proccessingHolo || this.state.loading ? "PROCESANDO ..." : "CAPTURAR Y CALIBRAR"}</button> <button type="button" onClick={() => { this.setState({ btnCancelar: true }); evento('Botón Cancelar', 'Click', { description: 'Cancelar' }, true) }} className={["btn btn-raised btn-primary forcewidth100 button_bg_gray", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} >CANCELAR</button> </div> : ("")}

                {!this.state.isHoloInstructions && this.state.intentsFront <= Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3")) && !this.state.proccessing && (<div className="module_buttons animated fadeIn delay-3s">
                    {this.state.side === "front" ? <button type="button" onClick={() => this.initRecord("front")} className={["btn btn-raised btn-primary forcewidth100 main_bg_color border_button_20 ", (isMobile) ? "margin_bottom_5_p" : "desk_id_capture_btn"].join(" ")} disabled={this.state.proccessing || this.state.loading}>{(this.state.proccessing || this.state.loading ? "PROCESANDO..." : "TOMAR FOTO")}</button> : <button type="button" onClick={() => this.initRecord("back")} className={["btn btn-raised btn-primary forcewidth100 main_bg_color border_button_20 ", (isMobile) ? "margin_bottom_5_p" : "desk_id_capture_btn"].join(" ")} disabled={this.state.proccessing || this.state.loading}>{(this.state.proccessing || this.state.loading ? "PROCESANDO..." : "TOMAR FOTO")}</button>}
                    <button type="button" onClick={() => this.setState({ btnCancelar: true })} className={["btn btn-raised btn-primary forcewidth100 border_button_20 button_bg_gray", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} >CANCELAR</button>
                </div>
                )}
                {((this.state.desktop && this.state.showIdOverlay && !this.state.isPassport) ? <div className="id_hold show_id_hold">
                    <div className="center_info_bx">
                        <img src="images/id_hold_icon.png" height="200" alt="" />
                        <p>Toma tu identificación solo con dos dedos <br /> sin obstruir tu foto u otra información<br />
                            <small>Sugerimos tomarla de la esquina superior izquierda</small>
                        </p>
                        <br />
                        <button type="button" className="btn btn-raised btn-primary forcewidth100 main_bg_color border_button_20" onClick={() => { this.setState({ showIdOverlay: false }); evento('Captura ID', 'Click', { description: 'ENTENDIDO EMPEZAR' }, true) }}>ENTENDIDO EMPEZAR</button>
                    </div>
                </div> : (""))}
                {((this.state.desktop && this.state.showIdOverlay && this.state.isPassport) ?
                    <div className="pass_hold show_id_hold">
                        <div className="center_info_bx">
                            <img src="images/pass_scan.svg" height="100" alt="" />
                            <p>Sólo necesitas escanear la sección baja de la identificación que contiene tus datos personales.<br />
                                <small>Procura no obstruir ningún dato o tu fotografía</small>
                            </p>
                            <br />
                            <button type="button" className="btn btn-raised btn-primary forcewidth100 main_bg_color" onClick={() => (this.setState({ showIdOverlay: false }))}>ENTENDIDO EMPEZAR</button>
                        </div>
                    </div> : (""))}

                {/*((this.state.isHoloInstructions && !this.state.isStart) ?
                    <div className="hollo_gif_bx show_id_hold">
                        <div className="center_info_bx">
                            <img src="images/holo_gif_web.gif" height="150" alt="" />
                            <p>Ubica tu identificación al centro, presiona capturar, mueve tu dispositivo <u className="font-weight-bolder text-lg">lentamente</u> hacia la izquierda y hacia la derecha.<br />
                                <small>Vamos a calibrar la cámara de tu dispositivo</small>
                            </p>
                            <br />
                            <button type="button" className="btn btn-raised btn-primary forcewidth100 main_bg_color" onClick={() => (this.setState({ isStart: true }))}>ENTENDIDO CALIBRAR</button>
                        </div>
                </div> : (""))*/}

                {this.state.intentsFront >= Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3")) ? (<div className="err_finale  animated slideInUp">
                    <div className="center_checks">
                        <h5>Proceso no exitoso </h5>
                        <p>Haz superado el número <b>máximo</b> de intentos para este proceso inténtalo nuevamente <b>más tarde</b>.</p>
                        <p>Si deseas mayor información no dudes en contactarnos con el <b>ID</b> de proceso <b>{(this.state.uuidTrx).split("-")[0]}</b> </p>
                        <br />
                        {
                            (this.state.pathname === "/Cw2qjkHFRvpLPryvUWXDcOPMEgHIYI9X") ?
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <p><b>Llámanos al:</b></p>
                                            <p><a className="help_phone" href={"tel:" + obtenerValorConfig(this.state.dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(this.state.dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}</a></p>
                                        </div>
                                        <div className="col">
                                            <p><b>Escríbenos a:</b></p>
                                            <p><a className="help_mail" href={"mailto:" + obtenerValorConfig(this.state.dataOtorgante, 'EMAIL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(this.state.dataOtorgante, "EMAIL_CONTACTO_PRINCIPAL")}</a></p>
                                        </div>
                                    </div>
                                </div>
                                :
                                ""
                        }
                        <div className="action_buttons noscroll_screen">
                            {
                                <button onClick={() => { this.reload(); evento('Captura ID', 'Click', { description: 'ENTENDIDO' }, true) }} className="btn btn-raised btn-primary forcewidth100 main_bg_color border_button_20">ENTENDIDO</button>
                            }
                        </div>
                    </div>
                </div>) : ("")}

                {(this.state.proccessing) && <LoaderOCR side={this.state.side} />}
                {(this.state.proccessingHolo) && <Loader />}

                {((this.state.loading && !this.state.showHoloAnimation) && <div className="sprite_stay_a animado"></div>)}
                {(isLandscape) && (
                    <div className="rotate_device">
                        <div className="center_info_bx">
                            <img src="images/rotate_device.svg" height="100" alt="" />
                            <p>Por favor usa tu dispositivo en vertical<br />
                                <small>Gira tu dispositivo para continuar</small>
                            </p>
                        </div>
                    </div>
                )}
                {(this.state.intentsData === 1) || this.state.errorCapture &&
                    <div className="overlay_box">
                        <div className="alert_box_bottom animated slideInDown">
                            <div className="background_atd">
                                <div className="padding_5">
                                    <p className="alert_text">{this.state.errorMessage}</p>
                                    <button type="button" onClick={(e) => {
                                        this.setState({ intentsData: 2, errorCapture: false });
                                        this.statusSE();
                                        evento('Captura ID', 'Click', { description: 'VOLVER A INTENTAR' }, true);
                                    }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_button border_button_20 button_width_60 animated fadeIn delay-2s">VOLVER A INTENTAR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {(this.state.errorMsg) ?
                    <div className="overlay_box">
                        <div className="alert_box_bottom animated slideInDown">
                            <div className="background_atd">
                                <div className="padding_5">
                                    <p className="alert_text">Se ha detectado un bloqueo de red, verifica tu conexión de internet</p>
                                    <button type="button" onClick={(e) => {
                                        this.setState({ errorMsg: false });
                                        this.statusSE();
                                        evento('Captura ID', 'Click', { description: 'VOLVER A INTENTAR' }, true);
                                    }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn border_button_20 button_width_60 animated fadeIn delay-2s">VOLVER A INTENTAR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
                }
                {(this.state.errorVideo) ?
                    <div className="overlay_box">
                        <div className="alert_box_bottom animated slideInDown">
                            <div className="background_atd">
                                <div className="padding_5">
                                    <p className="alert_text">Hemos detectado que la cámara de tu dispositivo está siendo usada por otra web en alguna pestaña, por favor asegúrate de cerrar las pestañas abiertas e inténtalo nuevamente.</p>
                                    <button type="button" onClick={(e) => {
                                        this.setState({ errorVideo: false });
                                        this.statusSE();
                                        window.location.reload();
                                        this.statusSE();
                                        evento('Captura ID', 'Click', { description: 'VOLVER A INTENTAR' }, true);
                                        //this.props.history.push("/identificacion")
                                    }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animated fadeIn delay-2s">VOLVER A INTENTAR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
                }
                <div className={(this.btnCancelar ? "modal-backdrop show" : "")}></div>
            </div>
        );
    }


}
CameraPhotoIOS = withOrientationChange(CameraPhotoIOS)
export default withRouter(CameraPhotoIOS)
