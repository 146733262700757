import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { enviarCorreoAceptacion, obtenerScore, crearSMS, actualizarEstado, finalUpdateScore } from '../services/api.js'
import { configStyle, obtenerValorUser, obtenerValorConfig, replaceValue } from '../services/configStyle';
import { isMobile, isIOS } from 'react-device-detect';
import { status, statusError, statusData, agregarInfo, esValidoEmail, hayExcepcion, enviarObjetoATD, evento, sendZip } from '../services/data'

let ConsultaHistorial = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [statError, setstatError] = useState(false);
    const [showCancel, setShowCancel] = useState(false)
    const [apiKey, setApiKey] = useState('')
    const [uuid, setUuid] = useState(null)
    const [dataUser, setDataUser] = React.useState([])
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataFace, setDataFace] = useState({})
    const [correo, setCorreo] = useState('')
    const [correoMsj, setCorreoMsj] = useState(false)
    const [changeStyleValue, setChangeStyleValue] = useState(false)
    const [focusCorreo, setFocusCorreo] = useState(false)
    const [inputActive, setInputActive] = useState(false)
    const { ruta } = useParams();
    const [flag, setFlag] = useState(false);
    const [contador, setContador] = useState(0);
    const [uuidTrx, setUuidTrx] = useState("");

    useEffect(() => {
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            setUuid(uuidUser)
        }
        let uuidTrx = localStorage.getItem("uuidTrx")
        if (uuidTrx) {
            setUuidTrx(uuidTrx)
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let dataFaceLS = localStorage.getItem("face")
        if (dataFaceLS != null) {
            setDataFace(JSON.parse(dataFaceLS))
        }

        status("correo_electronico_page", "Correo electrónico");
        actualizarScore()

    }, [])

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante]);

    const enableFullScreenSpace = () => {
        setChangeStyleValue(false)
    }

    const statusE = async () => {
        let data = "Cancelado";
        localStorage.setItem('flag', 'cancelado');
        await sendZip('TRUNCOS', 'trunco');
        await statusError("Correo_electronico", data, "cancelado");
        await enviarObjetoATD(false, true);
        setTimeout(() => {
            window.close()
            history.push("/" + (ruta !== "pdf_video_token" ? apiKey : `pdf_token?uuid=${localStorage.getItem("uuidUser")}&idOtorgante=${localStorage.getItem("idOtorgante")}&fecha=${localStorage.getItem("fechavigencia")}`))
        }, 300);
    }

    const continuar = (e) => {
        e.preventDefault();
        let continuar = true;
        let frase = '';
        let tipo = '';
        let informacion = {};
        tipo = 'Click';
        evento('Correo electrónico', tipo, { description: 'CONTINUAR' }, true);
        if (!esValidoEmail(correo)) {
            continuar = false; frase = "Debes introducir un formato de correo válido"
            document.getElementById("ht-correo").innerHTML = frase;
        } else {
            document.getElementById("ht-correo").innerHTML = "";
        }
        //console.log("vigencia",vigencia);
        if (continuar) {
            agregarInfo(dataUser, { description: "correoElectronico", value: correo });
            let objeto = { description: "correoElectronico", value: correo };

            sendData(objeto);
            enviarObjetoATD(true, false);
            enviarCorreo();
        }

    }

    const sendData = (objeto) => {
        statusData("correo_electronico", objeto)
        //var res = JSON.stringify(jsonObj)
    }

    const obtenerScoreUser = async () => {
        let uuidUser = localStorage.getItem("uuidUser");
        let tipo = '';
        let informacion = {};
        obtenerScore(uuidUser, !isMobile).then((response) => {
            if (response.status === 200) {
                informacion.status = response.status;
                informacion.payload = response.data.payload;
                evento('Obtener Score', 'Success', informacion, true);
                if (response.data.payload.flag) {
                    let data = { flag: response.data.payload.flag }
                    statusData("consulta_score", data);
                    setFlag(true);
                    let campos = JSON.stringify(response.data.payload.Fields)
                    localStorage.setItem("fields", campos);
                    localStorage.setItem("flag", response.data.payload.flag);
                } else {
                    console.log("sin bandera");
                    setContador(parseInt(contador) + 1)
                }
            }
            evento('Obtener score', tipo, informacion, true)
        }).catch((error) => {
            setContador(parseInt(contador) + 1)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Obtener Score', tipo, informacion, false);
        });
    }

    // Nuevo 13.10.21
    const printText = () => {
        let textToPrint = "";
        let textStorage = obtenerValorConfig(dataOtorgante, "TEXTO_DE_CONSENTIMIENTO_3")
        if (textStorage !== "") {
            textToPrint = replaceValue(textStorage, "{USUARIO}", obtenerValorUser(dataUser, "nombre"))
            textToPrint = replaceValue(textToPrint, "{OTORGANTE}", obtenerValorConfig(dataOtorgante, "RAZON_SOCIAL"))
        } else {
            textToPrint = "Resultados de Datos Biometricos capturados:"
        }
        return textToPrint
    }

    useEffect(() => {
        //console.log("contador", contador);
        if (!flag && parseInt(contador) < 10 && parseInt(contador) > 0) {
            obtenerScoreUser()
        } else {
            if (parseInt(contador) > 10) {
                console.log("sin dato en bandera");
                statusError("score_check", "Sin valores en el score", "error")
            }
        }
    }, [contador]);

    const convertdataJSON = () => {
        var fecha = ""
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        if (month < 10) {
            fecha = `${day}0${month}${year}`
        } else {
            fecha = `${day}${month}${year}`
        }
        var jsonObj = {};
        jsonObj.uuidUser = uuid;
        jsonObj.uuidTrx = uuidTrx;
        let uuidC = obtenerValorConfig(dataOtorgante, "NOMBRE_CORTO", "") + "_" + fecha + "_" + uuidTrx.split("-")[0];
        jsonObj.custom_uuid = uuidC.split(" ").join("_");
        jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack"));
        jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront"));
        jsonObj.data_user = JSON.parse(localStorage.getItem("data_user"));
        jsonObj.email = correo;
        //jsonObj = JSON.parse(jsonObj);
        ////console.log(res);
        return jsonObj;
    }

    const statusF = (error) => {
        //console.log("entre a status ine");
        statusError("email_check", error, "error")
    }

    const actualizarScore = () => {
      let tipo = "";
      let informacion = {};
      let objeto = { createScore: true };
      finalUpdateScore(objeto)
        .then((response) => {
          if (response.status === 200) {
            informacion.status = response.status;
            informacion.payload = response.data.payload;
            evento("Actualizar Score", "Success", informacion, true);
            setTimeout(() => {
              obtenerScoreUser();
            }, 300);
          }
        })
        .catch((error) => {
          if (error.response) {
            let {
              data: { message },
              status,
            } = error.response;
            informacion.mensaje = message;
            informacion.status = status;
            tipo = "Error";
          } else {
            informacion.error = error.toString();
            tipo = "Exception";
          }
          evento("Actualizar Score", tipo, informacion, false);
        });
    };

    const enviarCorreo = () => {
        let tipo = '';
        let informacion = {};
        let dataUser = [];
        let uuid = '';
        let uuidTrx = '';
        let bandera = '';
        let objeto = {};
        let dataUserLS = localStorage.getItem("data_user");
        let correo = '';
        if (dataUserLS) {
            dataUser = JSON.parse(dataUserLS);
        }
        let dataFace = null;
        let dataFaceLS = localStorage.getItem("face")
        if (dataFaceLS) {
            dataFace = JSON.parse(dataFaceLS);
        }
        let dataOtorgante = null;
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) {
            dataOtorgante = JSON.parse(dataOtorganteLS);
        }
        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser) {
            uuid = uuidUser;
        }
        let uuidTrxLS = localStorage.getItem("uuidTrx")
        if (uuidTrxLS) {
            uuidTrx = uuidTrxLS;
        }
        let banderaLS = localStorage.getItem("flag")
        if (banderaLS) {
            bandera = banderaLS;
        }
        setLoading(true);
        if (bandera === "green") {
            objeto.tipo_envio = 'COMPLETO';
        } else {
            objeto.tipo_envio = 'SEMAFORO';
        }
        objeto.semaforo = bandera;
        objeto.uuidUser = uuid;
        objeto.name = localStorage.getItem('nombreCompleto');
        correo = obtenerValorUser(dataUser, "email");
        objeto.email = correo;
        localStorage.setItem("correoElectronico", correo);
        objeto.fNac = obtenerValorUser(dataUser, "nacimiento");
        objeto.curp = obtenerValorUser(dataUser, "curp");
        objeto.vigencia = obtenerValorUser(dataUser, "vigencia");
        objeto.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {};
        objeto.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {};
        let fecha = ""
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        if (month < 10) {
            fecha = `${day}0${month}${year}`
        } else {
            fecha = `${day}${month}${year}`
        }
        let partner = obtenerValorConfig(dataOtorgante, "PARTNER", "")
        partner = partner.replace(" ", "_");
        if (partner !== "") {
            partner = partner + "_"
        }
        let name_otorgante = obtenerValorConfig(dataOtorgante, "NOMBRE_CORTO", "")
        name_otorgante = name_otorgante.replace(" ", "_");
        let custom_uuid = partner + name_otorgante + "_" + fecha + "_" + uuidTrx.split("-")[0];
        objeto.custom_uuid = custom_uuid;
        objeto.originOS = isIOS ? 'ios' : 'android';
        objeto.coincidencia = (dataFace.match ? "Coincide" : "Requiere validación");
        objeto.data_user = JSON.parse(localStorage.getItem("data_user")) || {};
        enviarCorreoAceptacion(objeto).then((response) => {
            informacion.status = response.status;
            informacion.payload = response.data.payload;
            evento('Envío correo', 'Success', informacion, true);
            if (response.status === 200) {
                //console.log(response);
                statusData("email_check", { email: correo });
                setTimeout(() => {
                    history.push('/carga_informacion');
                }, 300);
            }
        }).catch((error) => {
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Envío Correo', tipo, informacion, false);
        }).finally(() => {
            setLoading(false);
        })
    }


    return (
        <Fragment>
            {(showCancel) ?
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancelar proceso</h5>
                                <button onClick={() => {
                                    setShowCancel(false);
                                }} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={() => {
                                    evento('Correo electrónico', 'Click', { description: 'NO' }, true);
                                    setShowCancel(false);
                                }} className="btn btn-secondary modal_btn_w" data-dismiss="modal">NO</button>
                                <button type="button" onClick={() => {
                                    evento('Correo electrónico', 'Click', { description: 'SI', status: 'CANCELADO' }, true);
                                    statusE();
                                }} className="btn btn-raised btn-secondary modal_btn_w  main_bg_color">SI</button>
                            </div>
                        </div>
                    </div>
                </div> : ("")}
            <div className="main_gradient">
                <Header ruta="consulta_historial" />
                <div className="main_text_container">
                    <h1 className="animated">¿Tu correo es correcto?</h1>
                    <p>Recuerda que ahí te enviaré tu Reporte de Crédito en cuanto lo tenga</p>
                </div>
                <form>
                    {loading && <Fragment>
                        <div className="loader_container animated delay-4s top_35_p">
                            <div className="ispinner ispinner-large">
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                            </div>
                        </div>
                    </Fragment>}
                    <div className="ocr_data_display">

                        <div className={["form-group", "bmd-form-group", (focusCorreo ? "is-focused" : "")].join(" ")}>
                            <label htmlFor="emailInput" className="bmd-label-floating label_atd">Correo electrónico</label>
                            <input type="email" inputMode="email" name="correo" className="form-control input_atd" id="emailInput" defaultValue={correo} onChange={e => setCorreo(e.target.value)} onFocus={e => {
                                setFocusCorreo(true)
                                //console.log(inputActive);
                                setInputActive(true)
                            }} onBlur={e => {
                                enableFullScreenSpace()
                                if (correo === "") {
                                    setFocusCorreo(false)
                                }
                                setInputActive(false)
                            }} />
                            <span id="ht-correo" className="helper-text ht" data-error="wrong" data-success="right"></span>
                        </div>

                    </div>
                    <div className={(changeStyleValue ? "action_buttons div_bottom_relative" : "action_buttons noscroll_screen div_bottom_relative")}>
                        <button type="button" disabled={!correo} className={["btn", "btn-raised", "forcewidth100", 'border_button_20', ((!correo) ? "btn-secondary" : "btn-primary main_bg_color")].join(" ")} onClick={continuar}>CONTINUAR</button>
                        <button type="button" onClick={() => {
                            evento('Correo electrónico', 'Click', { description: 'CANCELAR' }, true);
                            setShowCancel(true)
                        }} className="btn btn-primary forcewidth100 button_bg_gray border_button_20">
                            CANCELAR</button>
                    </div>
                </form>
                <Footer />

            </div>
            <div className={(showCancel ? "modal-backdrop show" : "")}></div>
            {(statError) ?
                <div className="overlay_box">
                    <div className="alert_box">
                        <p className="animated slideInDown">Verifica tu <b>conexión de internet</b> para volver a enviar el correo y vuelve a intentar</p>
                        <button type="button" onClick={() => {
                            evento('Correo electrónico', 'Click', { description: 'VOLVER A INTENTAR' }, true);
                            setstatError(false);
                            setLoading(false);
                        }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animated fadeIn delay-2s">VOLVER A INTENTAR</button>
                    </div>
                </div>
                :
                ""
            }
        </Fragment>
    )
}

export default ConsultaHistorial